import { useState, useContext, useEffect } from 'react';
import TenantContext from 'contexts/TenantContext';
import TenantListContext from 'contexts/TenantListContext';
import { ITenant } from "interfaces/tenant";
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  CardActions,
  CardHeader,
  Avatar,
  IconButton,
  CircularProgress,
  Zoom,
  CardContent
} from '@material-ui/core';
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SettingsIcon from '@material-ui/icons/Settings';
import Divider from '@material-ui/core/Divider';
import { Icon } from "@material-ui/core";
import TenantAddDialog from 'components/tenants/TenantAddDialog';
import TenantDeleteDialog from 'components/tenants/TenantDeleteDialog';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { LoginUserModel } from 'models/login-user-model';
import { QueriesStatic } from 'graphql/queries-static';
import { TenantUserModel } from 'models/tenant-user/tenant-user-model';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '5px',
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
  card: {
    height: '150px',
    width: '90%',
    backgroundColor: '#000000',
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  },
  cardHeader: {
    height: '25%'
  },
  cardAction: {
    padding: 0
  },
  divider: {
    marginLeft: '4%',
    marginRight: '4%',
  },
  menuIcon: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    padding: '1%'
  },
  gridContainer: {
    padding: '1%',
    // marginLeft: '1%'
  },
  tooltip: {
    fontSize: "15px"
  },
  description: {
    height: "20%",
    color: grey[100],
    marginLeft: "4%",
    marginRight: "4%",
    paddingBottom: "1%",
    fontSize: "0.75rem",
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));

const Tenants: React.FC = (props: any) => {
  const { setTenant } = useContext(TenantContext);
  const { tenantList } = useContext(TenantListContext);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState<ITenant>();
  const [owners, setOwners] = useState<Record<string, boolean>>({})
  const classes = useStyles();
  const history = useHistory();

  const checkLoggedInUserIsOwner = async (tenantName: string) => {
    try {
      const userResponse = await GraphQLHelper.execute<LoginUserModel>(
        QueriesStatic.getUserEmail,
        {},
        LoginUserModel
      );
      if (!userResponse.error) {
        const { email } = userResponse.result as LoginUserModel;
        const roleResponse = await GraphQLHelper.execute<TenantUserModel>(
          QueriesStatic.getTenantUserRole,
          { email, tenant: tenantName },
          TenantUserModel
        );
        if (!roleResponse.error) {
          const { role } = roleResponse.result as TenantUserModel;
          return role.toLocaleLowerCase() === 'owner';
        }
      }
    } catch (error) {
      console.error('Error checking owner status:', error);
    }
    return false;
  };

  useEffect(() => {
    if (tenantList) {
      const ownerChecks = tenantList.map(async (tenant) => {
        const isOwner = await checkLoggedInUserIsOwner(tenant.name);
        return { [tenant.name]: isOwner };
      });

      Promise.all(ownerChecks).then((results) => {
        const ownerMap = Object.assign({}, ...results);
        setOwners(ownerMap);
      });
    }
  }, [tenantList]);

  const handleSelectTenant = (tnt: ITenant) => {
    setTenant(tnt);
    history.push(`/tenantdetails?t='${tnt.name}'`);
  }

  const handleTenantSettings = (tnt: ITenant) => {
    setTenant(tnt);
    history.push(`/tenantsettings?t='${tnt.name}'`);
  }

  const addDialogClosed = () => {
    setShowAddDialog(false);
  }
  
  const deleteDialogClosed = () => {
    setShowDeleteDialog(false);
  }

  const onDeleteDialogShow = (t: ITenant) => {
    setTenantToDelete(t);
    setShowDeleteDialog(true);
  }

  const onHelpClick = () => {
    window.open('https://docs.echo.stream/docs/tenants', '_blank');
  }

  return (
    <div className={classes.root}>
      <TenantAddDialog closed={addDialogClosed} show={showAddDialog} />
      <TenantDeleteDialog closed={deleteDialogClosed} show={showDeleteDialog} tenantToDelete={tenantToDelete} />
      <Grid container className={classes.gridContainer}>
        {tenantList && tenantList.map((t: ITenant, index: number) => 
        t.active ?
            <Grid key={index} item xs={3} style={{ marginBottom: '1%' , marginTop: '1%' }}>
              <Card className={classes.card} elevation={3}>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      T
                    </Avatar>
                  }
                  // title={`${t.name}(${t.region})`}
                  title={t.name}
                  subheader={t.region}
                />
                <p className={classes.description}>{t.description}</p>
                <Divider className={classes.divider} />
                <CardActions className={classes.cardAction} disableSpacing>
                  <Grid container>
                    <IconButton onClick={() => handleSelectTenant(t)} aria-label="add to favorites">
                      <AccountTreeIcon />
                    </IconButton>
                    <IconButton onClick={() => handleTenantSettings(t)} aria-label="add to favorites">
                      <SettingsIcon />
                    </IconButton>
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    {
                      owners[t.name] &&
                      <IconButton onClick={() => { onDeleteDialogShow(t); }} aria-label="share">
                        <Icon>
                            <img src='baseline-delete_outline-24px.svg' height={25} width={25}/>
                        </Icon>
                      </IconButton>
                    }
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          :
          <Grid key={index} item xs={3} style={{ marginBottom: '1%' , marginTop: '1%' }}>
            <Tooltip 
              title={<h1 className={classes.tooltip}>{t.name} will be ready soon. Try refreshing the page in a few moments to use your tenant.</h1>}
              TransitionComponent={Zoom}
              arrow
              >
              <Card className={classes.card} elevation={3}>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      <CircularProgress size={24} style={{ color: '#F5F5F5', position: 'absolute', top: '50%', left: '50%', marginLeft: '-12px', marginTop: '-12px' }} />
                    </Avatar>
                  }
                  title={t.name}
                  subheader={t.region}
                />
                <Divider className={classes.divider} />
              </Card>
              </Tooltip>
            </Grid>
        )}
      </Grid>
    </div>
  )
}

export default Tenants;